
import { Component, Vue } from 'vue-property-decorator';
import { permissionsForm } from '@/config/forms/permissions';

@Component({})
export default class Projects extends Vue {
  path = 'projects';
  form = permissionsForm;
  headers = [{
    text: 'Nombre',
    value: 'name',
  }, {
    text: 'Descripción',
    value: 'description',
  }, {
    text: 'Actions',
    value: 'actions',
    sortable: false,
  }];
}
